<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card loader-height="2" :loading="loading" :disabled="loading">
          <v-card-text class="pb-0 mb-2" v-if="!loading">
            <div class="d-flex align-center">
              <v-chip v-if="data.stage === 0" color="info" small label class="mr-2">Open</v-chip>
              <v-chip v-if="(data.stage === 1)" color="success" small label class="mr-2">Closed</v-chip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="!embed">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="shadow-off" icon small @click="$nova.gotoLink({path: '/pbc/project'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
                </template>
                <span>Back to projects</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="shadow-off" icon small @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
                </template>
                <span>Refresh data</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <div class="text-center" v-if="!embed">
                <v-btn v-if="data.stage === 1" color="info" small class="" @click="openprojectdialog=true">Re-open Project</v-btn>
                <v-btn v-else-if="data.stage === 0" color="success" small class="" @click="closeprojectdialog=true"><v-icon class="mr-1">mdi-lock</v-icon> Close Project</v-btn>
              </div> 
            </div>
          </v-card-text>
          <v-card-text class="d-flex align-center pt-0" v-if="!loading">
            <div class="d-flex align-center">
              <v-progress-circular
                :rotate="270"
                :size="70"
                :width="12"
                :value="progress"
                color="success"
                class="mr-4"
                v-if="!embed"
              >
                {{ progress }}%
              </v-progress-circular>
              <div class="">
                <div class="subtitle-1 font-weight-bold">
                  {{data.name}}
                </div>
                <div class="d-flex align-center">
                  <div class="">
                    <span class="font-weight-bold">Due Date: </span>
                    {{$nova.formatDate(data.duedate)}}
                  </div>
                  <v-icon>mdi-circle-small</v-icon>
                  <span label v-if="(Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000)) < 0)" class="error--text">{{( Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000))*-1)}} days overdue</span>
                  <span label v-else class="info--text">{{( Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000)))}} days remaining</span>
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div>
              <div class="d-flex align-center">
                <div class="d-flex align-center primary--text mr-4">
                  <span class="display-1">{{totalrequests}}</span>
                  <span class="ml-1 line-1">Total<br/>Requests</span>
                </div>
                <div class="d-flex align-center info--text mx-4">
                  <span class="display-1">{{(data.summary || {}).requested || 0}}</span>
                  <span class="ml-1 line-1">Yet to Respond<br/>Count</span>
                </div>
                <div class="d-flex align-center warning--text mx-4">
                  <span class="display-1">{{(data.summary || {}).pending || 0}}</span>
                  <span class="ml-1 line-1">Waiting for Approval<br/>Count</span>
                </div>
                <div class="d-flex align-center error--text ml-4">
                  <span class="display-1">{{(data.summary || {}).cancelled || 0}}</span>
                  <span class="ml-1 line-1">Cancelled<br/>Count</span>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-if="loading">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, article, article"
            ></v-skeleton-loader>
          </v-card-text>
          <v-card-text class="pt-0" v-else>
            <div class="d-flex align-center mb-2">
              <v-btn v-if="data.stage===0 && ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit'))" color="btnColor" class="white--text mr-2" small  @click="$nova.gotoLink({hash: 'add'})"><v-icon left>mdi-plus</v-icon>Add Request</v-btn>
              <v-btn v-if="!isFree && data.stage===0 && !embed &&  ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit'))" color="btnColor" class="white--text mr-2" small @click="bulkuploaddialog=true;bulkuploadfile=null"><v-icon left>mdi-plus</v-icon>Bulk Upload</v-btn>
              <v-btn v-if="requestlist.length > 0" color="btnColor" class="white--text mr-2" small  @click="downloadAllRequest()"><v-icon left>mdi-download</v-icon>Download All Requests</v-btn>
              <div v-if="pendingmail && data.stage===0 &&  ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit'))" class="d-flex mr-2 align-center">
                <v-btn color="btnColor" class="white--text" small :loading="sendmailloading" :disabled="sendmailloading" @click="sendMail()"><v-icon>mdi-mail-outline</v-icon>Send Mail</v-btn>
                <span class="ml-1 error--text">Mail not yet sent for {{pendingmail}} request(s)</span>
              </div>
              <v-spacer></v-spacer>
              <!-- <v-chip label outlined class="mr-2 border-left-error" small>Urgent</v-chip> -->
              <!-- <v-chip label outlined class="" small>
                <v-icon left color="success">mdi-email-check</v-icon>
                Mail sent
              </v-chip> -->
            </div>
            <!-- <div class="d-flex align-center mb-4">
              <div class="font-weight-bold mr-4 body-2">Filter: </div>
              <v-checkbox label="Waiting" class="mt-0 mr-4 info--text" dense hide-details color="info" v-model="stagefilter" multiple :value="0"></v-checkbox>       
              <v-checkbox label="Pending Approval" class="mt-0 mr-4 warning--text" dense hide-details color="warning" v-model="stagefilter" multiple :value="1"></v-checkbox>       
              <v-checkbox label="Cancelled" class="mt-0 mr-4 error--text" dense hide-details color="error" v-model="stagefilter" multiple :value="-1"></v-checkbox>       
              <v-checkbox label="Closed" class="mt-0 mr-4 success--text" dense hide-details color="success" v-model="stagefilter" multiple :value="2"></v-checkbox>       
            </div> -->
            <v-simple-table dense class="FC-Table radius-small border-on" v-if="requestlist.length > 0 && counter > 0">
              <thead>
                <tr>
                  <th scope="#" class="text-center">#</th>
                  <th scope="type" class="text-center" style="max-width:45px">Type</th>
                  <th scope="urgent" class="text-center" style="max-width:20px"></th>
                  <th scope="Category">
                    <div class="d-flex align-center">
                      <span>Category</span>
                      <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense max-height="300">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu</v-icon></v-btn>
                        </template>
                        <v-list dense class="border-left-default py-0">
                          <v-list-item class="py-0 px-0 my-0" v-for="(v,k) in categorylist" :key="k">
                            <v-list-item-title class="pa-2">
                              <div class="d-flex align-center pr-2">
                                <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="categoryfilter" multiple :value="v"></v-checkbox>
                                <span class="">{{v}}</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </th>
                  <th scope="description">Description</th>
                  <th scope="response">Response</th>
                  <th scope="location" class="text-center">Location</th>
                  <th scope="tasklist" class="text-center">Tasklist</th>
                  <th scope="due-date" class="text-center">Due Days</th>
                  <!-- <th scope="Information-expected-date" class="text-center">Information Owner's Expected Date</th> -->
                  <th scope="action" class="text-center">Action</th>
                  <th scope="status" class="text-center">
                    <span>Status</span><br/>
                    <div class="d-flex justify-center">
                      <v-btn icon small @click="statushelpdialog=true"><v-icon>mdi-help-circle</v-icon></v-btn>
                      <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu</v-icon></v-btn>
                        </template>
                        <v-list dense class="border-left-default py-0">
                          <v-list-item class="py-0 px-0 my-0">
                            <v-list-item-title class="pa-2">
                              <div class="d-flex align-center">
                                <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="0"></v-checkbox>
                                <v-icon class="info--text" left>mdi-timer-sand</v-icon>
                                <span class="info--text">Waiting Repsonse</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item class="py-0 px-0 my-0">
                            <v-list-item-title class="pa-2">
                              <div class="d-flex align-center">
                                <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="1"></v-checkbox>
                                <v-icon class="warning--text" left>mdi-timer-sand</v-icon>
                                <span class="warning--text">Pending Approval</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item class="py-0 px-0 my-0">
                            <v-list-item-title class="pa-2">
                              <div class="d-flex align-center">
                                <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="2"></v-checkbox>
                                <v-icon class="success--text" left>mdi-check-circle</v-icon>
                                <span class="success--text">Closed Request</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item class="py-0 px-0 my-0">
                            <v-list-item-title class="pa-2">
                              <div class="d-flex align-center">
                                <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="-1"></v-checkbox>
                                <v-icon class="" left>mdi-cancel</v-icon>
                                <span class="">Cancelled Request</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </th>
                  <!-- <th scope="last-modified" class="text-center">Last Modified</th> -->
                  <th scope="owner" class="text-center">
                    <span>Information Owner </span><br/>
                    <v-btn icon small @click="infoownersummarydialog = true"><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v,k) in filteredrequestlist">
                  <tr :key="k" class="hoverable">
                    <td class="text-center" style="white-space: nowrap;">{{k+1}}
                    </td>
                    <td class="text-center" style="max-width:45px">
                      <v-tooltip right content-class="tooltip-right" v-if="v.type==='text'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-text-short</v-icon>
                        </template>
                        <span>Text Request</span>
                      </v-tooltip>
                      <v-tooltip right content-class="tooltip-right" v-else-if="v.type==='fileupload'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-file-upload</v-icon>
                        </template>
                        <span>File Upload Request</span>
                      </v-tooltip>
                      <v-tooltip right content-class="tooltip-right" v-else-if="v.type==='fileuploadtolink'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-upload-network</v-icon>
                        </template>
                        <span>File Upload to a link Request</span>
                      </v-tooltip>
                    </td>
                    <td class="text-center" style="max-width:20px">
                      <v-tooltip bottom content-class="tooltip-bottom" v-if="v.urgent">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="error">mdi-alert-circle</v-icon>
                        </template>
                        <span>Urgent</span>
                      </v-tooltip>
                    </td>
                    <td>{{v.category}}</td>
                    <td>{{v.description}}</td>
                    <td class="response-td">
                      <div class="d-flex align-center">
                        <div v-if="v.type==='fileuploadtolink'" class="mr-2">
                          <div v-if="v.response">
                            File Uploaded
                            <v-btn small icon :href="v.uploadlink" target="_blank" class="shadow-off ml-2"><v-icon>mdi-launch</v-icon></v-btn>
                          </div>
                          <div v-else>NA</div>
                        </div>
                        <div v-else-if="v.type==='link'" class="mr-2">
                          <v-btn v-if="v.response" small icon :href="(v.response.substring(0, 9).search('http://') > -1 || v.response.substring(0, 9).search('https://') > -1)?v.response:('//'+v.response)" target="_blank" class="shadow-off ml-2">Link<v-icon right>mdi-launch</v-icon></v-btn>
                        </div>
                        <div v-else-if="v.type==='fileupload'" class="d-flex align-center">
                          <div v-if="v.fileid">
                            File Uploaded
                            <v-btn small icon class="shadow-off ml-2" @click="$nova.downloadFile(v.fileid)"><v-icon>mdi-download</v-icon></v-btn>
                          </div>
                          <div v-else>NA</div>
                        </div>
                        <div v-else-if="v.type==='multiselect'" class="mr-2 response-td-text">{{((v.response instanceof Array)?v.response:[]).join(", ") || v.response}}</div>
                        <div v-else class="mr-2 response-td-text">{{v.response || ''}}</div>
                        
                      </div>
                    </td>
                    <td class="text-center">{{ getLocationName(v.location) }}</td>
  
                    <td class="text-center ">
                      <v-menu
                        offset-x left open-on-hover
                        v-for="(item,index) in (v.reference.length >0 ? v.reference: v.selectlist)"
                        :key="index"
                        :max-width="400"
                        :max-height="100"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            outlined
                            class="pl-2 mb-1 mr-1"
                          >
                            <v-icon>mdi-tag</v-icon>
                            <span class="pl-1">{{getRefById(item?.selectlist ? item.selectlist : item)?.ref}}</span>
                          </v-chip>
                        </template>
                        <v-card class="shadow-off">
                          <v-card-text>{{getNameById(item?.selectlist ? item.selectlist : item)}}
                            <v-chip
                              class="ma-2"
                              color="cyan"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon left>
                                mdi-map-marker
                              </v-icon>
                              {{getlocationById(item)}}
                            </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    </td>
                    <td class="text-center">
                      <div v-if="new Date(v.duedate || '')">
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage === 3">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="25" color="error white--text" v-bind="attrs" v-on="on" v-if="((new Date(v.duedate || '') - new Date(v.reviewed_on)))/(1000*24*60*60) < -1">
                              {{Math.ceil((new Date(v.reviewed_on) - new Date(v.duedate || ''))/(1000*24*60*60))}}
                            </v-avatar>
                          </template>
                          <span>Delay</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(new Date(v.duedate || '') - new Date()) >= -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="25" color="info white--text" v-bind="attrs" v-on="on">
                              {{Math.ceil((new Date(v.duedate || '') - new Date())/(1000*24*60*60))}}
                            </v-avatar>
                          </template>
                          <span>Days Remaining</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(new Date(v.duedate || '') - new Date()) < -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="25" color="error white--text" v-bind="attrs" v-on="on">
                              {{Math.ceil((new Date() - new Date(v.duedate || ''))/(1000*24*60*60))}}
                            </v-avatar>
                              </template>
                          <span>Days Overdue</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <!-- <td class="text-center">
                      <div class="" v-if="v.expecteddate">
                        {{$nova.formatDate(v.expecteddate)}}
                        <br/>
                        <v-chip v-if="[0,1].indexOf(v.stage) > -1" label class="caption ml-1 px-2" :color="(Math.floor((new Date(v.expecteddate) - new Date())/(60*60*24*1000)) < 0)?'error':''" x-small>{{ Math.floor((new Date(v.expecteddate) - new Date())/(60*60*24*1000))}} Days</v-chip>
                      </div>
                      <div v-else>NA</div> 
                    </td> -->
                    <!-- <td class="hoverable cursor-pointer status-td">
                      
                    </td> -->
                    <td class="text-center">
                      <!-- <template v-for="(sv,sk) in statustext">
                        <div v-if="sk == 's'+v.stage" :key="sk">
                          <div v-if="data.stage!==0" :class="`${sv.color}--text font-weight-bold full-height d-flex align-center justify-center`" v-bind="attrs" v-on="on">
                            <span>{{sv.text}}</span>
                          </div>
                          <v-menu offset-y :key="sk" v-else transition="slide-y-transition" :disabled="statusloading[v._id]">
                            <template v-slot:activator="{ on, attrs }">
                              <div :class="`${sv.color}--text font-weight-bold full-height d-flex align-center justify-center`" v-bind="attrs" v-on="on">
                                <span>{{sv.text}}</span>
                                <v-btn icon loading x-small class="ml-3" color="grey lighten-1" v-if="statusloading[v._id]"></v-btn>
                                <v-icon loading class="ml-1" color="grey lighten-1" v-else>mdi-chevron-down</v-icon>
                              </div>
                            </template>
                            <v-list dense class="pa-0">
                              <template v-for="(mv, mk) in changestatustext">
                                <v-list-item :key="mk" v-if="mv.stages.indexOf(v.stage) > -1" @click="changeStage(v._id, mv.changeto)">
                                  <v-list-item-title>
                                    <span :class="`${mv.color}--text`">{{ mv.text }}</span> 
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-menu>
                        </div>
                      </template> -->
                      <div class="d-flex justify-center" v-if="($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit'))">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="shadow-off" small icon @click="responsedialog=true;addeditresponseheader=v;addeditresponse={response: v.response || '', remark: v.remark || ''}" v-bind="attrs" v-on="on">
                              <v-icon v-if="(v.stage !== 2)" color="">mdi-plus-circle</v-icon>
                              <v-icon v-else color="">mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span v-if="(v.stage === 2)">View Response</span>
                          <span v-else>Add/Edit Response</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="([2,-1].indexOf(v.stage) > -1)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small color="info" v-bind="attrs" v-on="on" @click="changeStage(v._id, 0)">
                              <v-icon>mdi-timer-sand</v-icon>
                            </v-btn>
                          </template>
                          <span>Send back to pending</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="([1].indexOf(v.stage) > -1)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small color="success" v-bind="attrs" v-on="on" @click="changeStage(v._id, 2)">
                              <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Approve Response</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="([1].indexOf(v.stage) > -1)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small color="error" v-bind="attrs" v-on="on" @click="changeStage(v._id, 0)">
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Reject Response</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="([0,1,2].indexOf(v.stage) > -1)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small color="" v-bind="attrs" v-on="on" @click="changeStage(v._id, -1)">
                              <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancel Request</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" @click="v.fileupload = (['fileupload', 'fileuploadtolink'].indexOf(v.type) > -1)?true:false;addrequestdialog = true;addeditrequest={...v};addeditrequesterror={};">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Request</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" @click="deletedialog = true;deletedata=v">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Request</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="markCommentAsRead(v, k);commentreqkey=k;commentsdialog=true;addeditresponseheader=v;commentsdata='';commentserror=''" v-bind="attrs" v-on="on" icon small>
                              <v-badge color="error" offset-x="10" offset-y="10" dot v-if="(v.comments || []).length > 0 && (((v.comments || [])[(v.comments || []).length - 1] || {}).readby || []).indexOf($store.state.user.id) === -1">
                                <v-icon class="">mdi-comment-text-multiple</v-icon>
                              </v-badge>
                              <v-icon v-else class="">mdi-comment-text-multiple</v-icon>
                            </v-btn>
                          </template>
                          <span>Conversations</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td class="text-center">
                      <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                        </template>
                        <span>Cancelled Request</span>
                      </v-tooltip>
                      <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                        </template>
                        <span>Waiting Response</span>
                      </v-tooltip>
                      <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                        </template>
                        <span>Pending Approval</span>
                      </v-tooltip>
                      <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                        </template>
                        <span>Approved Request</span>
                      </v-tooltip>
                    </td>
                    <td class="text-center">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar size="30" :style="`background-color:${owners[v.info_owner].color}`" v-bind="attrs" v-on="on">
                            {{(((v.info_owner_detail || {}).name).split(' ') || []).slice(0, 2).map(word => word[0].toUpperCase()).join('')}}
                          </v-avatar>
                        </template>
                        <div class="text-center">
                          {{(v.info_owner_detail || {}).name}}
                          <br/>
                          {{(v.info_owner_detail || {}).email}}
                        </div>
                      </v-tooltip>
                      <v-tooltip left content-class="tooltip-left" v-if="v.mail_count > 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="success" class="ml-1" >mdi-email-check</v-icon>
                        </template>
                        <span>Mail Sent</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table>
            <div v-else>
              <v-alert type="info" text border="left" :value="true">
                No Requests added yet.
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
        <v-row>
  
          <v-dialog v-model="typedialog" max-width="800">
            <v-card class="">
              <v-card-text class=" d-flex align-center py-2 px-4">
                <span class="subtitle-1 ">Request Types</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="typedialog = false;" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th scope="type">Type</th>
                      <th scope="description">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="hoverable">
                      <td><v-chip label text small>Text</v-chip></td>
                      <td>To obtain text response</td>
                    </tr>
                    <tr class="hoverable">
                      <td><v-chip label text small>File Upload</v-chip></td>
                      <td>To provide a file upload option</td>
                    </tr>
                    <tr class="hoverable">
                      <td><v-chip label text small>Upload Link</v-chip></td>
                      <td>Link to a public cloud folder where the user can upload the files</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="addrequestdialog" max-width="600" persistent>
            <v-card :loading="addrequestloading" :disabled="addrequestloading" loader-height="2" class="">
              <v-card-text class=" d-flex align-center py-2 px-4">
                <span class="subtitle-1 font-weight-bold" v-if="addeditrequest._id">Edit Request</span>
                <span class="subtitle-1 font-weight-bold" v-else>Create New Request</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="addrequestdialog = false;" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4 pt-4">
                <v-row>
                  <v-col cols="12" class="my-0 py-0">
                    <lb-string v-model="addeditrequest.description" label="Description" :error="addeditrequesterror.description"/>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <lb-dropdown class="flex-grow-1" :customvalue="true" :items="categorylist" v-model="addeditrequest.category" label="Category" :error="addeditrequesterror.category"/>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <div class="d-flex align center">
                      <lb-dropdown class="flex-grow-1" :items="infoownerlist" itemtext="name" itemvalue="_id" v-model="addeditrequest.info_owner" label="Information Owner" :error="addeditrequesterror.info_owner"/>
                      <v-btn color="" outlined text class="ml-2 shadow-off" v-if="!!addeditrequest.info_owner" @click="addinfoownerdialog = true;addeditinforowner=infoownerlistids[addeditrequest.info_owner]">Edit Selected</v-btn>
                      <v-btn color="" outlined text class="ml-2 shadow-off" @click="addinfoownerdialog = true">Create New</v-btn>
                    </div>
                  </v-col>
                  <v-col  cols="12" class="my-0 py-0">
                    <lb-dropdown class="flex-grow-1" :items="locationList" itemtext="name" itemvalue="_id" v-model="addeditrequest.location" :label="$store.state.LocationHeader ? $store.state.LocationHeader  : 'Location' " :error="addeditrequesterror.location"/>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0" v-if="(reflist || []).length > 0">
                    <v-col cols="12" class="my-0 py-0">
                      <div class="d-flex align-center mb-2 mt-2">
                      <span class="mr-2 font-weight-bold">Tasklist:</span><v-btn class="shadow-off" small text @click="addedittaskdialog = true; selectchecklist_id=''"><v-icon class="mr-1">mdi-plus</v-icon>Add Taskist</v-btn>
                    </div>
                    </v-col>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <v-simple-table dense class="mb-4" v-if="addeditrequest?.selectlist?.length > 0 || addeditrequest?.reference?.length > 0">
                      <thead>
                        <tr>
                          <th scope="Red">Ref</th>
                          <th scope="Name">Tasklist</th>
                          <th scope="Action">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="item,index in (addeditrequest.reference.length >0 ? addeditrequest.reference : addeditrequest.selectlist )">
                          <tr :key="index">
                            <td class="  ">
                              {{getRefById(item?.selectlist ? item.selectlist : item)?.ref}}
                            </td>
                            <td>{{getNameById(item?.selectlist ? item.selectlist : item)}}</td>
                            <td>
                              <v-icon class="ma-1" color="error" @click="removetasklist(item)">mdi-delete</v-icon>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <div class="d-flex align-center">
                      <v-checkbox class="mt-0 pt-0" v-model="addeditrequest.fileupload" label="File Upload?" :error="addeditrequesterror.fileupload"/>
                      <v-checkbox class="mt-0 pt-0 mx-4" v-model="addeditrequest.urgent" label="Urgent?" :error="addeditrequesterror.urgent"/>
                      <lb-date v-model="addeditrequest.duedate" label="Due Date (Optional)" :error="addeditrequesterror.duedate" :min="$nova.formatDateISO(new Date())" />
                    </div>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <lb-string v-if="addeditrequest.fileupload" v-model="addeditrequest.uploadlink" :label="`Link For File Upload ${isFree?'':'(Optional)'}`" :error="addeditrequesterror.uploadlink"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-spacer></v-spacer>
                <v-btn small color="btnColor" class="white--text" v-if="addeditrequest._id" @click="editRequest()">Update</v-btn>
                <v-btn small color="btnColor" class="white--text" v-else @click="addRequest()">Create</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
          <lb-dailogboxnew
            v-model="addedittaskdialog"
            :heading="addeditrequest._id ? 'Update' : $store.state.LocationHeader ? $store.state.LocationHeader  +' Based Tasklist' :'Location Based Tasklist'"
            width="600"
            :loading="addedittaskloading"
          >
            <template v-slot:body>
              <div>
                <v-row>
                  <v-col cols="12" class="my-0 py-0 mt-2">
                    <lb-dropdown class="flex-grow-1" :items="reflist" v-model="selectchecklist_id" label="Tasklist" :error="addeditrequesterror.reference"/>
                  </v-col>
                </v-row>
              </div>
            </template>
            <template v-slot:actions>
              <span class="caption">*Required</span>
              <v-spacer></v-spacer>
              <v-btn small color="btnColor" class="white--text" @click="addEditTaskItem()">
                <span>Add</span>
              </v-btn>
            </template>
          </lb-dailogboxnew>
  
          <v-dialog v-model="addinfoownerdialog" max-width="500" persistent>
            <v-card :loading="addinfoownerloading" :disabled="addinfoownerloading" loader-height="2" class="">
              <v-card-text class="  d-flex align-center py-2 px-4">
                <span class="subtitle-1 " v-if="addeditinforowner._id">Edit Information Owner</span>
                <span class="subtitle-1 " v-else>Add New Information Owner</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="addinfoownerdialog = false;" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4 pt-4">
                <v-row>
                  <v-col cols="12" class="my-0 py-0">
                    <lb-string v-model="addeditinforowner.name" label="Name" :error="addeditinforownererror.name"/>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <lb-string v-model="addeditinforowner.email" label="Email" :error="addeditinforownererror.email"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-spacer></v-spacer>
                <v-btn small color="btnColor" class="white--text" v-if="addeditinforowner._id" @click="editInfoOwner()">Update</v-btn>
                <v-btn small color="btnColor" class="white--text" v-else @click="addInfoOwner()">Add</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="deletedialog" max-width="600" persistent>
            <v-card :loading="deleteloading" :disabled="deleteloading" loader-height="2" class="">
              <v-card-text class=" d-flex align-center py-2 px-4">
                <span class="subtitle-1 ">Delete Request</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="deletedialog = false" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <div class="title mb-2">{{deletedata.description}}</div>
                <div class="mb-2">
                  <div class="">
                    <span class="font-weight-bold mr-3">Requested From:</span>
                    <span>{{(deletedata.info_owner_detail || {}).name}}</span>
                    <span class="ml-1">({{(deletedata.info_owner_detail || {}).email}})</span>
                  </div>
                  <div class="d-flex align-center">
                    <span class="font-weight-bold mr-3">Information Type:</span>
                    <v-chip label small>{{requesttype[deletedata.type] || "Unknown"}}</v-chip>
                  </div>
                  <div class="mt-4">
                    <div class="font-weight-bold mb-2">This will permanently delete this request. Do you confirm?</div>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-spacer></v-spacer>
                <v-btn small color="btnColor" class="white--text" @click="deleteRequest(deletedata._id)">Confirm</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="responsedialog" max-width="600" persistent>
            <v-card :loading="responseloading" :disabled="responseloading" loader-height="2" class="">
              <v-card-text class="  d-flex align-center py-2 px-4">
                <span class="subtitle-1 " v-if="(addeditresponseheader.stage === 2)">View Response</span>
                <span class="subtitle-1 " v-else>Add/Edit Response</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="responsedialog = false" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <v-chip label small text color="error" class="mb-2" v-if="addeditresponseheader.urgent">Urgent</v-chip>
                <div class="title mb-2">{{addeditresponseheader.description}}</div> 
                <div class="mb-2">
                  <div class="">
                    <span class="font-weight-bold mr-3">Requested From:</span>
                    <span>{{(addeditresponseheader.info_owner_detail || {}).name}}</span>
                    <span class="ml-1">({{(addeditresponseheader.info_owner_detail || {}).email}})</span>
                  </div>
                  <div class="d-flex align-center">
                    <span class="font-weight-bold mr-3">Information Type:</span>
                    <v-chip label small class="pr-0">{{requesttype[addeditresponseheader.type] || "Unknown"}} <v-btn icon small @click="typedialog=true"><v-icon small>mdi-help-circle-outline</v-icon></v-btn></v-chip>
                    <v-btn v-if="addeditresponseheader.type === 'fileuploadtolink'" :href="addeditresponseheader.uploadlink" target="_blank" small text class="shadow-off ml-2">Folder Link Here <v-icon right x-small>mdi-launch</v-icon></v-btn>
                  </div>
                  <div class="mt-4">
                    <div class="font-weight-bold mb-2 d-flex align-center">
                      <span>Response</span>
                      <v-chip label small text color="info" class="ml-2" v-if="addeditresponseheader.stage===0">Pending</v-chip>
                      <v-chip label small text color="warning" class="ml-2" v-else-if="addeditresponseheader.stage===1">Waiting for Approval</v-chip>
                      <v-chip label small text color="success" class="ml-2" v-else-if="addeditresponseheader.stage===2">Closed</v-chip>
                      <v-chip label small text color="error" class="ml-2" v-else-if="addeditresponseheader.stage===-1">Cancelled</v-chip>
                    </div>
                    <v-checkbox v-if="addeditresponseheader.type === 'fileuploadtolink'" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0"
                      v-model="addeditresponse.response" inset dense class="pt-0 mt-0" label="Uploaded?" 
                    ></v-checkbox>
                    <lb-string v-else-if="addeditresponseheader.type === 'text'" v-model="addeditresponse.response" label="Text" :error="addeditresponseerror.response" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0"/>
                    <div v-else-if="addeditresponseheader.type === 'link'" class="d-flex">
                      <lb-string v-model="addeditresponse.response" label="URL Link" :error="addeditresponseerror.response" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0"/>
                      <v-btn v-if="addeditresponse.response && data.stage===0" text outlined class="ml-2 shadow-off" @click="$nova.copyText(addeditresponse.response)">Copy Link<v-icon right>mdi-content-copy</v-icon></v-btn>
                    </div>
                    <lb-number v-else-if="addeditresponseheader.type === 'number'" v-model="addeditresponse.response" label="Number" :error="addeditresponseerror.response" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0"/>
                    <v-radio-group dense class="pt-0 mt-0" v-else-if="addeditresponseheader.type === 'choice'" v-model="addeditresponse.response" column :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0">
                      <v-radio v-for="cv in (addeditresponseheader.selectlist || [])" :key="cv" :label="cv" :value="cv"></v-radio>
                    </v-radio-group>
                    <div v-else-if="addeditresponseheader.type === 'multiselect'" class="mb-4" >
                      <v-checkbox dense class="pt-0" multiple :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0"
                        v-for="cv in (addeditresponseheader.selectlist || [])" :key="cv"
                        v-model="addeditresponse.response" :label="cv" :value="cv" hide-details
                      ></v-checkbox>
                    </div>
                    <div v-else-if="addeditresponseheader.type === 'fileupload'">
                      <div class="" v-if="addeditresponseheader.fileid_detail">
                        <span class="font-weight-bold pr-2">Uploaded:</span>
                        {{addeditresponseheader.fileid_detail.originalname || ""}}
                        <v-btn icon @click="$nova.downloadFile(addeditresponseheader.fileid)"><v-icon>mdi-download</v-icon></v-btn>
                      </div>
                      <lb-file :remove="true" v-model="addeditresponse.response" :error="addeditresponseerror.response" v-if="[-1,2].indexOf(addeditresponseheader.stage) === -1 && data.stage===0"/>
                    </div>
                    <lb-textarea v-model="addeditresponse.remark" label="Remark (Optional)" :error="addeditresponseerror.remark" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1 || data.stage!==0"/>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="d-flex" v-if="[-1,2].indexOf(addeditresponseheader.stage) === -1 && data.stage===0">
                <v-spacer></v-spacer>
                <v-btn small color="btnColor" class="white--text" @click="updateResponse()">Update</v-btn>
              </v-card-text>
              <v-card-text v-else class="pt-0">
                <span class="font-weight-bold mr-1">Note:</span>
                <span>Response cannot be modified if the request is Closed/Cancelled/Approved or if the project is closed.</span>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="bulkuploaddialog" max-width="1200" persistent>
            <v-card :loading="bulkuploadloading" :disabled="bulkuploadloading" loader-height="2" class="">
              <v-card-text class="  d-flex align-center py-2 px-4">
                <span class="subtitle-1 ">Bulk Upload Request</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="bulkuploaddialog = false" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <div class="mb-2">
                  Upload the file here
                  <v-btn outlined small @click="downloadBulkUploadTemplate()" text class="ml-2 shadow-off"><v-icon left>mdi-download</v-icon> Download Template</v-btn>
                </div>
                <lb-file v-model="bulkuploadfile" :remove="true"/>
              </v-card-text>
              <v-card-text>
                <div v-if="Object.keys(errorBulkRequestData).length > 0">
                  <v-alert type="error" text border="left" v-if="errorBulkRequestData.error" :value="true">
                    {{errorBulkRequestData.error}}
                  </v-alert>
                  <v-alert type="error" text border="left" v-else :value="true">
                    We found few error in the uploaded data. Please resolve the same to proceed with the bulk upload.
                  </v-alert>
                </div>
                <v-simple-table v-if="okBulkRequestData.length > 0">
                  <thead>
                    <tr>
                      <th scope="#">#</th>
                      <th scope="status">Status</th>
                      <th scope="description">Category</th>
                      <th scope="description">Description</th>
                      <th scope="info-owner">Info Owner</th>
                      <th scope="file-upload">File Upload</th>
                      <th scope="file-upload-link">File Upload Link</th>
                      <th scope="urgent">Urgent</th>
                      <th scope="due-date">Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in okBulkRequestData" :key="k" class="hoverable">
                      <td>{{k+1}}</td>
                      <td>
                        <div v-if="errorBulkRequestData['index'+k]">
                          <v-chip label small color="error">Error</v-chip><br/>
                          <div class="error--text" v-html="errorBulkRequestData['index'+k].join('<br/>')"></div>
                        </div>
                        <v-chip v-else label small color="info">Pending Upload</v-chip>
                      </td>
                      <td>{{v.category || ""}}</td>
                      <td>{{v.description || ""}}</td>
                      <td>{{v.info_owner_name || ""}}<br/>{{v.info_owner_email || ""}}</td>
                      <td>{{(v.fileupload)?"Yes":"No"}}</td>
                      <td>{{v.uploadlink}}</td>
                      <td>{{(v.urgent)?"Yes":"No"}}</td>
                      <td>{{$nova.formatDate(v.duedate)}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div v-else-if="(bulkuploadfile || {}).name" class="grey--text title">
                  No Data found
                </div>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-spacer></v-spacer>
                <v-btn v-if="okBulkRequestData.length > 0 && Object.keys(errorBulkRequestData).length === 0" small color="btnColor" class="white--text" @click="bulkUploadRequest(deletedata._id)">Upload</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="infoownersummarydialog" max-width="1000" persistent>
            <v-card class="" :loader-height="2" :loading="infoownersummaryloading" :disabled="infoownersummaryloading">
              <v-card-text class=" d-flex align-center py-2 px-4">
                <span class="subtitle-1">Information Owner Wise Summary</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="infoownersummarydialog = false" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="px-0 mx-0 pb-2">
                <v-simple-table v-if="Object.keys(infoownersummarylist).length > 0" dense>
                  <thead>
                    <tr>
                      <th scope="name">Name</th>
                      <th scope="email">Email</th>
                      <th scope="pending" class="text-center">Pending</th>
                      <th scope="wating-approval" class="text-center">Waiting Approval</th>
                      <th scope="closed" class="text-center">Closed</th>
                      <th scope="cancelled" class="text-center">Cancelled</th>
                      <th scope="last-mail-date" class="text-center" style="min-width: 120px">Last Mail Date</th>
                      <th scope="mail-action" class="text-center">Mail Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in infoownersummarylist" :key="k" class="hoverable">
                     
                      <td>{{v.name || ""}}</td>
                      <td>{{v.email || ""}}</td>
                      <td class="text-center">
                        <v-chip v-if="v.pending" small color="info">{{v.pending || 0}}</v-chip>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <v-chip v-if="v.approve" small color="warning">{{v.approve || 0}}</v-chip>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <v-chip v-if="v.close" small color="success">{{v.close || 0}}</v-chip>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <v-chip v-if="v.cancel" small color="error">{{v.cancel || 0}}</v-chip>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <div v-if="v.lastmail" class="font-weight-bold caption">
                          {{$nova.formatDate(v.lastmail)}} 
                        </div>
                        <div v-if="v.lastmail">
                          {{Math.floor((new Date() - new Date(v.lastmail))/(60*60*24*1000))}} day(s) ago
                        </div>
                        <div v-else>NA</div>
                      </td>
                      <td class="text-center">
                       
                        <div v-if="(v.mailpending || 0) == 0" class="success--text">
                          <v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
                          All Sent
                          <v-btn color="info" class="ml-2" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete')" small @click="sendMail('reminder', v._id)">
                            Re-send
                          </v-btn>
                        </div>
                        <div v-else class="info--text">
                          <div class="d-flex align-center justify-center">
                            <v-icon color="info" class="mr-1">mdi-timer-sand</v-icon>
                            <span class="mr-1">{{v.mailpending || 0}}</span>
                            <span>Pending</span>  
                            <v-btn color="info" class="ml-2" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete')" small @click="sendMail('reminder', v._id)">
                              Send
                            </v-btn>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div v-else class="grey--text title">
                  No Data available
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="commentsdialog" max-width="600" persistent>
            <v-card :loading="commentsloading" :disabled="commentsloading" loader-height="2" class="">
              <v-card-text class="  d-flex align-center py-2 px-4">
                <span class="subtitle-1 ">Add/View Comments</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="commentsdialog = false" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <div v-if="(addeditresponseheader.comments || []).length === 0" class="title text-center my-5 grey--text">
                  No Comments added yet
                </div>
                <div v-else>
                  <div class="">
                    <perfect-scrollbar class="comments-container">
                      <template v-for="(v,k) in addeditresponseheader.comments">
                        <div :class="`d-flex align-center ${($store.state.user.id!==v.user)?'':'flex-row-reverse text-right'}`" :key="k">
                          <div :class="`comment-text px-4 py-2 my-2 ${($store.state.user.id!==v.user)?'grey lighten-2':'text-left'}`">{{v.comment}}</div>
                          <div class="mx-1 caption">
                            {{$nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "")}}
                          </div>
                        </div>
                      </template>
                    </perfect-scrollbar>
                  </div>
  
                </div>
  
              </v-card-text>
              <v-card-text v-if="data.stage===0 && ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit') || $nova.hasRight(right, 'delete'))" class="d-flex align-center" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1">
                <lb-string v-model="commentsdata" label="Write here..." :error="commentserror" hidedetails/>
                <v-btn small color="btnColor" class="white--text ml-2" @click="addComment()">Add</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="closeprojectdialog" max-width="600" persistent>
            <v-card :loading="closeprojectloading" :disabled="closeprojectloading" loader-height="2" class="">
              <v-card-text class="pt-6">
                <div class="title mb-4">Confirm!</div>
                <div>
                  This will close this project and the requestee will not be able to respond to the pending request, if any.<br/>
                  <span class="font-weight-bold">Do you want to proceed?</span>
                </div>
              </v-card-text>
              <v-card-text class="text-right">
                <v-btn small @click="closeprojectdialog = false" class="">Cancel</v-btn>
                <v-btn small class="ml-2" color="success" @click="closeProject()">Proceed</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="openprojectdialog" max-width="600" persistent>
            <v-card :loading="openprojectloading" :disabled="openprojectloading" loader-height="2" class="">
              <v-card-text class="pt-6">
                <div class="title mb-4">Confirm!</div>
                <div>
                  Do you want to re-open this project?<br/>
                </div>
              </v-card-text>
              <v-card-text class="text-right">
                <v-btn small @click="openprojectdialog = false" class="">Cancel</v-btn>
                <v-btn small class="ml-2" color="info" @click="openProject()">Proceed</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
  
          <v-dialog v-model="statushelpdialog" max-width="600" persistent>
            <v-card class="">
              <v-card-text class=" d-flex align-center py-2 px-4">
                <span class="subtitle-1">Status Notations</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="statushelpdialog = false" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="pa-4">
                <div class="my-1">
                  <v-icon color="info">mdi-timer-sand</v-icon>
                  <span class="ml-2">Waiting Response</span>
                </div>
                <v-divider></v-divider>
                <div class="my-1">
                  <v-icon color="warning">mdi-timer-sand</v-icon>
                  <span class="ml-2">Pending Approval</span>
                </div>
                <v-divider></v-divider>
                <div class="my-1">
                  <v-icon color="success">mdi-check-circle</v-icon>
                  <span class="ml-2">Approved Request</span>
                </div>
                <v-divider></v-divider>
                <div class="my-1">
                  <v-icon color="">mdi-cancel</v-icon>
                  <span class="ml-2">Cancelled Request</span>
                </div>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-dialog>
  
        </v-row>
      </div>
    </div>
    <v-snackbar v-model="snackbar_status" auto-heigh :color="snackbar_text.type" :timeout="3000" bottom center>
              <div class="text-center">
                  {{snackbar_text.content}}
              </div>
      </v-snackbar>
  </div>
  </template>
  
  <script>
  // @ is an alias to /src
  // import pageHeader from '@/components/pageHeader'
  // import managelist from '@/components/ManageList'
  import XLSX from "xlsx-color";
  export default {
    name: 'pbc_request_list',
    props: {
      embedid: {
        type: String,
        default: undefined,
      },
      embed: {
        type: Boolean,
        default: false,
      },
      api: {
        type: String,
        default: "",
      },
      reflist: {
        type: Array,
        default() {
          return []
        }
      },
      // required: {
      //   type: Boolean,
      //   default: false
      // }
    },
    data: function(){
      return {
        right:"financialclose_pbc_request",
        pageprops: {
          name: "Projects",
          breadcrumbs: [],
        },
        id: '',
        isFree: true,
        statustext: {
          's-1': {text: 'Cancelled', color: 'error'},
          's0': {text: 'Waiting', color: 'info'},
          's1': {text: 'Pending Approval', color: 'warning'},
          's2': {text: 'Closed', color: 'success'}
        },
        changestatustext: {
          'pending': {text: 'Pending', stages: [2,-1], changeto: 0, color: 'info'},
          'approve': {text: 'Approve', stages: [1], changeto: 2, color: 'success'},
          'close': {text: 'Close', stages: [0], changeto: 2, color: 'success'},
          'cancel': {text: 'Cancel', stages: [0,1,2], changeto: -1, color: 'error'},
        },
        requesttype: {
          "text": "Text",
          "number": "Number",
          "fileupload": "File Upload",
          "link": "URL Link",
          "fileuploadtolink": "File Upload Link",
          "multiselect": "Multi Select",
          "choice": "Choice",
        },
        totalrequests: 0,
        counter: 0,
        pendingmail: 0,
        progress: 30,
        typedialog: false,
        statushelpdialog: false,
        addeditrequest: {},
        addeditrequesterror: {},
        addeditinforowner: {},
        addeditinforownererror: {},
        addeditresponseheader: {},
        addeditresponse: {},
        addeditresponseerror: {},
        infoownerlist: [],
        infoownerlistids: {},
        statusloading: {},
        deleteloading: false,
        deletedialog: false,
        deletedata: {},
        sendmailloading: false,
        addrequestdialog: false,
        addrequestloading: false,
        addinfoownerdialog: false,
        addinfoownerloading: false,
        responsedialog: false,
        responseloading: false,
        bulkuploaddialog: false,
        bulkuploadloading: false,
        bulkuploadfile: null,
        okBulkRequestData: [],
        errorBulkRequestData: {},
        commentsdialog: false,
        commentsloading: false,
        commentsdata: "",
        commentserror: "",
        commentreqkey: -1,
        closeprojectdialog: false,
        closeprojectloading: false,
        openprojectdialog: false,
        openprojectloading: false,
        infoownersummarylist: [],
        infoownersummarydialog: false,
        infoownersummaryloading: false,
        loading: false,
        data: {},
        baseurl: this.api || "/v2/pbc/",
        owners: {},
        requestlist: [],
        categorylist: ["Others"],
        categoryfilter: [],
        stagefilter: [0, 1],
        selectdata: {
          "type": ["Text", "Number", "File Upload", "Multi Select", "Choice", "File Upload to link"],
        },
        snackbar_text: { type: '', content: '' },
        snackbar_status: false,
        locationList:[],
        addedittaskdialog:false,
        selectchecklist_id:'',
        addedittaskloading:false,
      }
    },
    created() {
      this.refreshData();
    },
    activated() {
      this.refreshData();
    },
   
  
    methods: {
      refreshData() {
        if(this.$route.hash === "#add") {
          this.addNewRequest();
        }
        
        if((this.$store.state.subscriptions.pbc || {}).Free) this.isFree = true;
        else this.isFree = false;
        if(this.embedid) this.id = this.embedid;
        else this.id = this.$route.params.id;
        this.progress = 0;
        return this.getData();
      },
      addEditTaskItem(){
        this.addedittaskdialog = true;
        if (!Object.keys(this.addeditrequest).includes('selectlist') || !Object.keys(this.addeditrequest).includes('reference')) {
          this.addeditrequest.selectlist = [];
          this.addeditrequest.reference = [];
        }
        if(this.addeditrequest.reference.length !==0 && this.selectchecklist_id )
        {
          this.addeditrequest.reference= this.addeditrequest.reference.includes(this.selectchecklist_id) ? [...this.addeditrequest.reference] : [...this.addeditrequest.reference, this.selectchecklist_id]
        }
        else if(this.addeditrequest.selectlist.length !==0 && this.selectchecklist_id )
        {
          let filtered_task = this.reflist.find((item)=> item.value=== this.selectchecklist_id)        
          this.addeditrequest.selectlist.some(item => item.selectlist === this.selectchecklist_id ||  item.selectlist === filtered_task.master_id) ? " ":  this.addeditrequest.selectlist.push({ selectlist: filtered_task.master_id, location: filtered_task.location }) ;
        }
        this.selectchecklist_id=''
        this.addedittaskdialog = false;
      },
      removetasklist(item){
  
  
        if(this.addeditrequest.reference.length !==0  )
        {
          let arr = this.addeditrequest.reference.filter(function(i) {
            return i !== item
          })  
          this.addeditrequest.reference = arr;
        }
        else if(this.addeditrequest.selectlist.length !==0)
        {      
          const index = this.addeditrequest.selectlist.findIndex(selectItem => 
            selectItem.selectlist === item.selectlist && selectItem.location === item.location
          );
          if (index !== -1) {
              this.addeditrequest.selectlist.splice(index, 1);
          }
        }
  
      },
      getData() {
        this.loading = true;
        return this.axios.post(this.baseurl + "project/get/"+this.id).then(dt => {
          if(dt.data.status === "success"){
            if(dt.data.data.length > 0){
              this.data = dt.data.data[0];
              // count total request
              if(this.data.summary) this.totalrequests = this.data.summary.requested + this.data.summary.pending + this.data.summary.approved + this.data.summary.cancelled;
              this.progress = (((((this.data.summary || {}).approved || 0) + ((this.data.summary || {}).cancelled || 0))/this.totalrequests)*100 || 0).toFixed(0);
              return this.axios.post(this.baseurl + "project/getrequests/"+this.id)
            }else {
              if(!this.embed) this.$nova.gotoLink({path: '/pbc/project'});
              throw new Error("");
            }
          }else throw new Error ("No project found");
        }).then(dt => {
          this.requestlist = [];
          if(dt.data.status === "success"){
            if(dt.data.data.length > 0){
              this.requestlist = dt.data.data || [];
  
              // populate reference summary
              let refsummary = {};
              for (const i of this.requestlist) {
                for (const j of (i.reference || [])) {
                  refsummary[j] = refsummary[j] || {total: 0, pending: 0, completed: 0, cancelled: 0}; 
                  refsummary[j].total ++
                  if(i.stage === -1) refsummary[j].calcelled++;
                  else if(i.stage === 2) refsummary[j].completed++;
                  else refsummary[j].pending++;
                }
              }
              // send pbc data in case of embed
              this.$emit('refresh', {items: this.requestlist, referencesummary: refsummary});
              
              // for owner colors
              this.owners = {};
              this.pendingmail = 0;
              for (let i = 0; i < this.requestlist.length; i++) {
                const el = this.requestlist[i];
                if(el.category){
                  if(this.categorylist.indexOf(el.category) === -1) this.categorylist.push(el.category);
                }else el.category = "Others"
                this.owners[el['info_owner']] = {};
                if(el.mail_count === 0 && el.stage === 0) this.pendingmail++;
              }
              this.categoryfilter = this.categorylist;
              let colors = this.$nova.getColors(Object.keys(this.owners).length, 0.5);
              for (let i = 0; i < Object.keys(this.owners).length; i++) {
                const el = Object.keys(this.owners)[i];
                this.owners[el].color = colors[i];
              }
            }
            return this.axios.post(this.baseurl + "infoowner/list");
          }else throw new Error ("Error fetching request details");
        }).then(dt => {
          if(dt.data.status === "success"){
            if(dt.data.data.length > 0) {
              this.infoownerlist = [];
              this.infoownerlistids = {};
              for (let i = 0; i < dt.data.data.length; i++) {
                const el = dt.data.data[i];
                this.infoownerlist.push({"_id": el._id, "name": el.name + " ("+ el.email +")"});
                this.infoownerlistids[el._id] = el;
              }
            }
            this.viewInfoOwnerSummary();
          }else throw new Error ("Error fetching information owner list");
        }).then(() => {
          return this.axios.post("/v2/financialclose/location/get").then((dt) => {
            if (dt.data.status === "success") {
              this.locationList = dt.data.data;
              this.loading = false;
            } else throw new Error(dt.data.message);
          });
        }).catch(err => {
          if(err.message){
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          }
        }).finally(() => {
          this.counter ++;
          this.loading = false;
        })
      },
      viewInfoOwnerSummary: function(){
        let fdt = {};
        for (let i = 0; i < this.requestlist.length; i++) {
          const el = this.requestlist[i];
          fdt[el.info_owner] = fdt[el.info_owner] || {lastmail: ((el.info_owner_detail || {}).mails || {}).action_date, _id: el.info_owner, name: (el.info_owner_detail || {}).name, email: (el.info_owner_detail || {}).email, pending: 0, approve: 0, close: 0, cancel: 0, mailsent: 0, mailpending: 0 };
          let info = fdt[el.info_owner];
          if((el.mail_count || 0) > 0) info.mailsent++;
          else info.mailpending++;
          if(el.stage === -1) info.cancel = info.cancel+1;
          else if(el.stage === 0) info.pending = info.pending+1;
          else if(el.stage === 2) info.close = info.close+1;
          else if(el.stage === 1) info.approve = info.approve+1;
          fdt[el.info_owner] = info;
        }
        this.infoownersummarylist = fdt;
        
      },
      changeStage(id, s) {
        this.statusloading[id] = true;
        console.log(this.statusloading);
        this.axios.post(this.baseurl + "request/changestage/"+id+"/"+s).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Status changed");
            this.refreshData();
          }else throw new Error (dt.data.message);
        }).catch(err => {
          this.statusloading[id] = false;
          this.counter ++;
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.statusloading[id] = false;
        })
      },
      addNewRequest(){
        this.addrequestdialog = true;
        this.addeditrequest={};
        this.addeditrequesterror={};
      },
      beforeRequestUpdate(d){
        for (let i = 0; i < d.length; i++) {
          d[i].project = this.id;
          if(this.isFree){
            if(d[i].fileupload) d[i].type = "fileuploadtolink";
            else d[i].type = "text";
          }
          else{
            if(d[i].fileupload) {
              if(d[i].uploadlink) d[i].type = "fileuploadtolink";
              else d[i].type = "fileupload";
            }
            else d[i].type = "text";
          }
        }
        return d;
      },
      closeProject() {
        this.closeprojectloading = true;
        this.axios.post(this.baseurl + "project/close/"+this.id).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Project Closed Successfully");
            this.closeprojectdialog = false;
            this.refreshData();
          }else{
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.closeprojectloading = false;
        })
      },
      openProject() {
        this.openprojectloading = true;
        this.axios.post(this.baseurl + "project/open/"+this.id).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Project re-opened Successfully");
            this.openprojectdialog = false;
            this.refreshData();
          }else{
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.openprojectloading = false;
        })
      },
      addRequest() {
        this.addrequestloading = true;
        let rdata = this.beforeRequestUpdate([this.addeditrequest]);
        this.axios.post(this.baseurl + "request/add/", {data: rdata}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "New Request Added");
            this.addeditrequest = {};
            this.addrequestdialog = false;
            this.$nextTick(() => {
              this.refreshData();
            });
          }else{
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.addeditrequesterror = dt.data.data[0].index0;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
  
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          // this.local_sankbar('Ticket updated successfully', 'success');
          // console.log(err);
        }).finally(() => {
          this.addrequestloading = false;
        })
      },
      bulkUploadRequest: function(){
        this.bulkuploadloading = true;
        let rdata = this.beforeRequestUpdate(this.okBulkRequestData);
        this.axios.post(this.baseurl + "request/add/", {data: rdata}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Bulk Requests Added");
            this.bulkuploaddialog = false;
            this.refreshData();
          }else{
            if (dt.data.data.length > 0){
              let er = {};
              for (let i = 0; i < dt.data.data.length; i++) {
                const el = dt.data.data[i];
                let etxt = [];
                let fk = Object.keys(el)[0];
                for (const k in el[fk]) {
                  if (Object.hasOwnProperty.call(el[fk], k)) {
                    const nel = el[fk][k];
                    let netxt = "";
                    netxt = netxt + "'" + k + "': "
                    if(nel instanceof Array) netxt = netxt + nel.join(",");
                    else netxt = netxt + nel.toString();
                    if(netxt !== "") etxt.push(netxt);
                  }
                }
                er[fk] = etxt;
              }
              this.errorBulkRequestData = er;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.bulkuploadloading = false;
        });
      },
      editRequest() {
        this.addrequestloading = true;
        this.beforeRequestUpdate([this.addeditrequest]);
        let newData = {};
        newData._id = this.addeditrequest._id || "";
        newData.description = this.addeditrequest.description || "";
        newData.category = this.addeditrequest.category || undefined;
        if(this.addeditrequest.duedate) newData.duedate = this.addeditrequest.duedate || "";
        newData.info_owner = this.addeditrequest.info_owner || "";
        newData.type = this.addeditrequest.type || "";
        newData.response = "";
        newData.urgent = this.addeditrequest.urgent || false;
        newData.selectlist = this.addeditrequest.selectlist || [];
        newData.uploadlink = this.addeditrequest.uploadlink || "";
        newData.location=this.addeditrequest.location,
        newData.reference = this.addeditrequest.reference || [],
        this.axios.post(this.baseurl + "request/edit/"+newData._id, {data: newData}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Request Updated");
            this.addrequestdialog = false;
            this.addeditrequest = {};
            this.$nextTick(() => {
              this.refreshData();
            });
          }else{
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.addeditrequesterror = dt.data.data[0].index0;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.addrequestloading = false;
        })
      },
      deleteRequest(id) {
        this.deleteloading = true;
        this.axios.post(this.baseurl + "request/delete/"+id).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Request Deleted");
            this.deletedialog = false;
            this.refreshData();
          }else throw new Error ((dt.data.message) || "error deleting the request");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.deleteloading = false;
        })
      },
      updateResponse() {
        this.responseloading = true;
        Promise.resolve().then(() => {
          if(this.addeditresponseheader.type === "fileupload") return this.$nova.uploadFile(this.axios, this.addeditresponse.response);
        }).then(dt => {
          if(this.addeditresponseheader.type === "fileupload"){
            if(dt.data.status === "success" && dt.data.data.length > 0){
              this.addeditresponse.fileid = dt.data.data[0]._id;
            }else throw new Error ("Unable to upload file");
          }
          return this.axios.post(this.baseurl + "request/updateresponse/"+this.addeditresponseheader._id, {data: this.addeditresponse})
        }).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Response Updated");
            this.responsedialog = false;
            this.refreshData();
          }else{
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.addeditresponseerror = dt.data.data[0].index0;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.responseloading = false;
        })
      },
      addInfoOwner() {
        this.addinfoownerloading = true;
        
        this.axios.post(this.baseurl + "infoowner/add/", {data: this.addeditinforowner}).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.$store.commit("sbSuccess", "New Information Owner Added");
            this.addinfoownerdialog = false;
            let tis = this;
            let reqdata = {...this.addeditrequest};
            this.refreshData().then(function(){tis.addeditrequest = reqdata; tis.addeditrequest.info_owner = dt.data.data[0]._id || ""});
          }else{
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.addinfoownerloading = false;
        })
      },
      editInfoOwner() {
        this.addinfoownerloading = true;
          
        this.axios.post(this.baseurl + "infoowner/edit/"+this.addeditinforowner._id, {data: this.addeditinforowner}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Updated Owner Information");
            this.addinfoownerdialog = false;
            let t = this.addeditrequest.info_owner;
            this.addeditrequest.info_owner = null;
            let tis = this;
            let reqdata = {...this.addeditrequest};
            this.refreshData().then(function(){tis.addeditrequest = reqdata; tis.addeditrequest.info_owner = t});
          }else{
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.addinfoownerloading = false;
        })
      },
      addComment() {
        this.commentsloading = true;
        this.axios.post(this.baseurl + "request/addcomment/"+this.id, {data: [{comment: this.commentsdata, request: this.addeditresponseheader._id || ''}]}).then(dt => {
          if(dt.data.status === "success"){
            if(dt.data.data.length > 0){
              this.$store.commit("sbSuccess", "Comment Added");
              this.requestlist[this.commentreqkey].comments = this.requestlist[this.commentreqkey].comments || [];
              this.requestlist[this.commentreqkey].comments.push(dt.data.data[0])
              this.addeditresponseheader = this.requestlist[this.commentreqkey];
              this.commentsdata='';
              this.commentserror='';
            }else throw new Error ("error adding comment");
          }else{
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
            }
            throw new Error (dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.commentsloading = false;
        })
      },
      markCommentAsRead(x, k) {
        if((x.comments || []).length > 0){
          let commentkey = x.comments.length -1;
          let comment = x.comments[commentkey]; 
          if((comment.readby || []).indexOf(this.$store.state.user.id) === -1){
            this.axios.post(this.baseurl + "request/markcommentasread/"+x._id).then(dt => {
              if(dt.data.status === "success" && dt.data.response.nModified > 0){
                this.requestlist[k].comments[commentkey].readby.push(this.$store.state.user.id);
              }else throw new Error (dt.data.message);
            }).catch(err => {
              this.$store.commit("sbError", err.message || err || "Unknown error!");
              console.log(err);
            }).finally(() => {
              this.commentsloading = false;
            })
          }
        }
      },
      sendMail(t = "", info_owner = "") {
        let ndt = {};
        if(t === 'reminder'){
          ndt.info_owner = [info_owner]
          ndt.type = "reminder";
        }
        this.sendmailloading = true;
        this.infoownersummaryloading = true;
        this.axios.post(this.baseurl + "request/sendmail/"+this.id, {data: [ndt]}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Mail sent");
            this.refreshData();
          }else throw new Error (dt.data.message) || "Error sending mail";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.sendmailloading = false;
          this.infoownersummaryloading = false;
        })
      },
      downloadBulkUploadTemplate(){
        let rows = [["description", "category", "info_owner_name", "info_owner_email", "file_upload", "file_upload_link", "urgent", "due_date"]];
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "bulk_request_template.csv");
        document.body.appendChild(link);
        link.click();
      },
      downloadAllRequest(){
        const headers = [ 
          "SL No", 
          "Type", 
          "Category", 
          "Description", 
          "Response", 
          "Location",
          "Linked Task",
          "Due Date", 
          "Status", 
          "Info-Owner Name",
          "Info-Owner Email", 
          "Remarks" 
          // gettask
        ];      
        const data = this.requestlist.map((item, index) => ({
          "SL No" :index + 1,
          'Type' : (item.type === 'text') ? "Text":((item.type === 'fileuploadtolink') ? "File Upload to Link" : ((item.type === 'fileupload') ? "File Upload":"Unknown")),
          'Category' :  item.category,
          'Description' :item.description,
          'Response' : item.response,
          'Location': this.getLocationName(item.location),
          'Linked Task': item.selectlist.length > 0 ? item.selectlist.map(selectItem => this.getRefById(selectItem.selectlist).ref).join(", ") : item.reference.map(item => this.gettask(item).ref).join(", ") ,
          'Due Date':  this.$nova.formatDate(item.duedate),
          'Status' : item.stage === -1 ? "Cancelled" : item.stage === 0 ? "Waiting Response" : item.stage === 1? "Pending Approval": item.stage===2 ? 'Closed' : "Unknown" ,
          'Info-Owner Name' : (item.info_owner_detail || {}).name,
          'Info-Owner Email' : (item.info_owner_detail || {}).email,
          'Remarks' : item.remark
        }));

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([], { header: headers }); 
        XLSX.utils.sheet_add_aoa(ws, [[" ", 'Project-name',"-",this.data.name,"","","","","","","",]], { origin: 'A1' }); 
          ws['B1'].s = {
            font: {
              bold: true
            }
          };
        XLSX.utils.sheet_add_aoa(ws, [[""]], { origin: 'A2' }); 
        XLSX.utils.sheet_add_json(ws, data, { header: headers, origin: 'A3' });

        headers.forEach((header, index) => {
            const cell_address = XLSX.utils.encode_cell({ c: index, r: 2 });
            if (!ws[cell_address]) return;
            ws[cell_address].s = {
                font: {
                    bold: true,
                    color: { rgb: "FFFFFF" }               
                  },
                fill: {
                    fgColor: { rgb: "black" } 
                },
                alignment: {
                  vertical: "center",
                  horizontal: "start",
              },
                
            };
        });
        ws['!cols'] = headers.map((header) => {
          if (header === 'Description') {
              return { wch: 40 }; 
          }
          else if (header === 'SL No') {
              return { wch: 5 }; 
          }
          return { wch: 20 };
      });

    XLSX.utils.book_append_sheet(wb, ws, 'requests');
    XLSX.writeFile(wb, this.data.name + '-requests.xlsx');
      },
      validateBulkRequest(dt){
        return new Promise((resolve, reject) => {
          console.log(dt);
          Promise.resolve().then(() => {
            let okdata = [];
            let error = {};
            let infoowners = {};
            for (const k in this.infoownerlistids) {
              if (Object.hasOwnProperty.call(this.infoownerlistids, k)) {
                const el = this.infoownerlistids[k];
                infoowners[el.email] = el._id;
              }
            }
            for (let i = 0; i < dt.length; i++) {
              const el = dt[i];
              console.log(el);
              if(i === 0){
                if(el.join(",") !== "description,category,info_owner_name,info_owner_email,file_upload,file_upload_link,urgent,due_date"){
                  reject("all required columns were not found!");
                  break;
                }
              }else if(el.length === dt[0].length){
                // console.log("in")
                let lerr = [];
                let lokd = {};
                if(el[0]) lokd["description"] = el[0];
                else lerr.push("Description is required");
                if(el[1]) lokd["category"] = el[1];
                if(!el[3]) lerr.push("info_owner_email is required");
                else if(infoowners[el[3]]) {
                  lokd["info_owner"] = infoowners[el[3]];
                  lokd["info_owner_email"] = el[3];
                  lokd["info_owner_name"] = el[2];
                }
                else if(!el[2]) lerr.push("info_owner_name is required");
                else {
                  lokd["info_owner_email"] = el[3];
                  lokd["info_owner_name"] = el[2];
                }
                let fu = (el[4] || "").toLowerCase();
                if(el[5]) lokd["uploadlink"] = el[5];
                else lokd["uploadlink"] = "";
                if(fu === "y" || fu === "yes"){
                  lokd["fileupload"] = true
                  if(this.isFree){
                    if(!lokd["uploadlink"]) lerr.push("file_upload_link is required");
                  }
                }else lokd["fileupload"] = false
                let ur = (el[6] || "").toLowerCase();
                if(ur === "y" || ur === "yes") lokd["urgent"] = true;
                else lokd["urgent"] = lokd["urgent"] = false;
                if(el[7]){
                  if(this.$nova.isValidDate(el[7], "DD-MM-YYYY")) lokd["duedate"] = this.$nova.formatDateISO(el[7], "DD-MM-YYYY");
                  else lerr.push("Invalid due_date"); 
                }
                if(lerr.length > 0) {
                  error['index'+(okdata.length)] = lerr;
                  lokd["status"] = false;
                }else lokd["status"] = true;
                okdata.push(lokd);
              }else if(el.length > 1){
                let nd = {...el};
                nd["status"] = -1;
                okdata.push(nd);
                error['index'+(okdata.length)] = ["Incomplete data"];
              }
            }
            resolve ({"data": okdata, "error": error});
          }).catch(err => {
            reject(err);
          })
        })
      },
      local_sankbar (content, type) {
        console.log("FF");
              this.snackbar_text.content = content
              this.snackbar_text.type = type ?? 'success'
              this.snackbar_status = true
          },
    },
    computed: {
      requesttypedropdown: function(){
        let r = [];
        for (const k in this.requesttype) {
          if (Object.hasOwnProperty.call(this.requesttype, k)) {
            const el = this.requesttype[k];
            r.push({name: el, value: k});
          }
        }
        return r;
      },
      filteredrequestlist: function() {
        let dt = [];
        for (const i of this.requestlist) {
          if (this.stagefilter.indexOf(i.stage) > -1 && this.categoryfilter.indexOf(i.category) > -1) dt.push(i);
        }
        return dt;
      },
      getLocationName() {
      return (id) => {
        const location = this.locationList.find(loc => loc._id === id);
        return location ? location.name : '';
      };
      },
      getlocationById() {
        
        return (id) => {
          
          
          if( typeof id === 'string')
          {
            let taskdetail = this.reflist.filter(loc => loc.value === id );
            if(taskdetail)
            {
              taskdetail = this.getRefById(taskdetail.value)
            }
            return taskdetail ? taskdetail.location : '';
          }
          if(typeof id === 'object' && Object.keys(id).includes('location') )
          {
            let locationID = this.getLocationName(id?.location)
  
            return locationID ? locationID : 'NA';
          }
      };
      },
      getNameById() {
        
        return (id) => {
          console.log("wertyuiawsedrt",this.reflist, id);
          
          const taskdetail = this.reflist.find(loc => loc.value === id || loc.master_id===id);
          return taskdetail ? taskdetail.name : 'NA';
        };
      },
      getRefById() {
        return (id) => {
          const reference = this.reflist.find(loc => loc.value === id || loc.master_id===id);
          return reference ? reference : 'NA';
        };
      },
      gettask(){
        return (id) => {
          const reference = this.reflist.find(loc => loc.value === id );
          return reference ? reference : 'NA';
        };
      }
    },
    watch: {
      "$store.state.client": function(){
        this.refreshData();
      },
      "$route": function(d){
        if(d.hash === "#add") this.addNewRequest();
      },
      "addrequestdialog": function(d){
        if(!d) this.$nova.gotoLink({hash: ''});
      },
      "bulkuploadfile": function(d){
        this.okBulkRequestData = [];
        this.errorBulkRequestData = {};
        if(d){
          this.$nova.readCSV(d, {}).then(dt => {
            console.log(dt);
            return this.validateBulkRequest(dt);
          }).then(dt => {
            this.okBulkRequestData = dt.data || [];
            this.errorBulkRequestData = dt.error || {};
            console.log(this.errorBulkRequestData);
          }).catch(err => {
            console.log(err);
            this.errorBulkRequestData["error"] = err;
          })
        }else this.okBulkRequestData = [];
      }
    }
  }
  </script>
  
  <style>
  .pbc-view-table .status-td{
    max-width: 120px !important;
  }
  /* .pbc-view-table .sl-no{
    max-width: 30px !important;
  } */
  .response-td{
    max-width: 300px !important;
  }
  .response-td .response-td-text{
     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     line-clamp: 1; 
     -webkit-line-clamp: 1; /* number of lines to show */
     -webkit-box-orient: vertical;
  }
  </style>
  
  <style lang="scss" scoped>
    .comment-text {
      max-width: 80%;
      border-radius: 15px;
      border: 1px solid var(--v-primary-base);
    }
    .comments-container{
      max-height: 50vh;
    }
  </style>